import { TipoInputEnum } from "./tipoInput";

export const InputBMPTDF2Enum = {
    InvestimentoDeAcoes: { idProgramaToInput: 64, nome: "Investimento das ações", id_tipo_input: TipoInputEnum.ValorUnico },
    QuantidadeHorasTurnoInicial: { idProgramaToInput: 65, nome: "Quantidade de horas por turno", id_tipo_input: TipoInputEnum.MedicaoInicial },
    QuantidadeHorasTurnoFinal: { idProgramaToInput: 66, nome: "Quantidade de horas por turno", id_tipo_input: TipoInputEnum.MedicaoFinal },
    NumeroOperadoresTurnoInicial: { idProgramaToInput: 67, nome: "Número de operadores por turno", id_tipo_input: TipoInputEnum.MedicaoInicial },
    NumeroOperadoresTurnoFinal: { idProgramaToInput: 68, nome: "Número de operadores por turno", id_tipo_input: TipoInputEnum.MedicaoFinal },
    OEEInicial: { idProgramaToInput: 69, nome: "OEE", id_tipo_input: TipoInputEnum.MedicaoInicial },
    OEEFinal: { idProgramaToInput: 70, nome: "OEE", id_tipo_input: TipoInputEnum.MedicaoFinal },
    DisponibilidadeOEEInicial: { idProgramaToInput: 71, nome: "Disponibilidade OEE", id_tipo_input: TipoInputEnum.MedicaoInicial },
    DisponibilidadeOEEFinal: { idProgramaToInput: 72, nome: "Disponibilidade OEE", id_tipo_input: TipoInputEnum.MedicaoFinal },
    PerformanceOEEInicial: { idProgramaToInput: 73, nome: "Performance OEE", id_tipo_input: TipoInputEnum.MedicaoInicial },
    PerformanceOEEFinal: { idProgramaToInput: 74, nome: "Performance OEE", id_tipo_input: TipoInputEnum.MedicaoFinal },
    QualidadeOEEInicial: { idProgramaToInput: 75, nome: "Qualidade OEE", id_tipo_input: TipoInputEnum.MedicaoInicial },
    QualidadeOEEFinal: { idProgramaToInput: 76, nome: "Qualidade OEE", id_tipo_input: TipoInputEnum.MedicaoFinal },
    QuantidadeProduzidaInicial: { idProgramaToInput: 78, nome: "Quantidade produzida nas horas trabalhadas por turno", id_tipo_input: TipoInputEnum.MedicaoInicial },
    QuantidadeProduzidaFinal: { idProgramaToInput: 79, nome: "Quantidade produzida nas horas trabalhadas por turno", id_tipo_input: TipoInputEnum.MedicaoFinal },
    GanhoFinanceiro: { idProgramaToInput: 80, nome: "Ganho Financeiro Mensal", id_tipo_input: TipoInputEnum.ValorUnico },
    CnpjFornecedorPrincipal: { idProgramaToInput: 77, nome: "CNPJ do fornecedor principal", id_tipo_input: TipoInputEnum.Cnpj },
};
