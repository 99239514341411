import axios from 'axios';
import {BASE_URL} from './API';

export default function DownloadFiles (urlFile, nomeArquivo) {

    const method = 'GET';
    
    if(!urlFile.startsWith('a')){
        urlFile = 'a'+ urlFile;
    }
    let url = BASE_URL + urlFile;

    axios.request({ url, method, responseType: 'blob', }).then(({ data }) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', nomeArquivo);
        document.body.appendChild(link);
        link.click();
        link.remove();
    });
}
