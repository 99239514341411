import React, { useState } from 'react';
import Masks from '../../comm/masks';
import { Icon } from '@material-ui/core';

const ContatoBox = ({ contato, editaElemento, removeElemento, isReview }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isDeleteHovered, setIsDeleteHovered] = useState(false);

    const handleMouseEnter = () => {
        if (!isDeleteHovered && !isReview) {
            setIsHovered(true);
        }
    };

    const handleMouseLeave = () => {
        if (!isReview) {
            setIsHovered(false);
        }
    };

    return (
        <div 
            className={'box'} 
            data-index={contato.nome}
            id='contato'
            onClick={!isReview ? editaElemento : null} 
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{ 
                cursor: isReview ? 'default' : 'pointer', 
                position: 'relative', 
                padding: '15px',
                transition: 'all 0.3s ease',
                boxShadow: (!isDeleteHovered && isHovered && !isReview) ? '0px 4px 10px rgba(0, 0, 0, 0.1)' : 'none',
                transform: (!isDeleteHovered && isHovered && !isReview) ? 'translateY(-5px)' : 'none'
            }}
        >
            <label>
                CONTATO
                {!isReview && ( 
                    <Icon
                        className="delete-icon" 
                        id="contato"
                        onClick={(e) => {
                            e.stopPropagation(); 
                            removeElemento(contato.nome);
                        }}
                        onMouseEnter={() => setIsDeleteHovered(true)}
                        onMouseLeave={() => setIsDeleteHovered(false)}
                        style={{
                            transition: 'transform 0.3s ease, color 0.3s ease',
                            transform: isDeleteHovered ? 'scale(1.3)' : 'scale(1)',
                            color: isDeleteHovered ? '#ff0000' : 'inherit'
                        }}
                    >
                        clear
                    </Icon>
                )}
            </label>
            <hr />
            
            {contato.nome && <div>{contato.nome}</div>}
            {contato.setor && <div>{contato.setor}</div>}
            {contato.telefone && <div>{contato.telefone}</div>}
            {contato.email && <div>{contato.email}</div>}
            {contato.cpf && <div>{Masks.maskByTipoPessoa(contato.cpf, 0)}</div>}

            {!isDeleteHovered && isHovered && !isReview && (
                <div style={{
                    position: 'absolute',
                    top: '-40px',
                    right: '10px',
                    padding: '5px 10px',
                    backgroundColor: '#333',
                    color: '#fff',
                    borderRadius: '4px',
                    fontSize: '12px',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                    opacity: '0.8',
                    transition: 'opacity 0.3s ease'
                }}>
                    Editar Contato
                </div>
            )}
        </div>
    );
}

export default ContatoBox;
