import { Grid } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import React, { Component } from 'react';
import { MdAttachFile } from "react-icons/md";
import DownloadFiles from '../../comm/DownloadFiles';
import OpenFile from "../../comm/OpenFile";
import GenericButton from '../../components/genericButton/genericButton';
import DownloadFilesNewBackend from "../../comm/DownloadFilesNewBackend";

export default class Anexos extends Component {
  


    anexos = (this.props.isModuloNacional && this.props.list) ? this.props.list.sort((i, j) => (i.anexoReferencial.id > j.anexoReferencial.id) ? 1 : -1) : null;
    
    
    showFileName(nomeArquivoAnexo) {
        let anexo = nomeArquivoAnexo;
        try{

            anexo = decodeURIComponent(escape(nomeArquivoAnexo));
        }catch(e) {
                anexo = nomeArquivoAnexo;
        }
        return anexo;
    }

    checkIfCanEditIndicadores = () => {
        //Usuários podem editar indicadores/anexos caso estejam sem status ou não conformes.
        //Usuários não podem editar caso ele esteja em análise ou conformes.
        if (this.props.atendimento.solicitacaoStatus == null) {
            this.canEditIndicadores = true;
        } else {
            this.canEditIndicadores = (
                this.props.atendimento.solicitacaoStatus.chave === 'naoconforme' ||
                this.props.atendimento.solicitacaoStatus.chave === 'naoconformeIA' ||
                this.props.atendimento.solicitacaoStatus.chave === 'naoconformeIA180' ||
                this.props.atendimento.solicitacaoStatus.chave === 'naoconformeIPEA' ||
                this.props.atendimento.solicitacaoStatus.chave === 'naoconformeIE' ||
                this.props.atendimento.solicitacaoStatus.chave === 'naoconformeLEAN' ||
                this.props.atendimento.solicitacaoStatus.chave === 'naoconformeDIGITAL'
            );
        }
        return this.canEditIndicadores;
    }; 

    buttonAnexos = () => {
        const status = this.props.atendimento.atendimentoStatus.chave;
        if (status === 'aceito' || status === 'emexecucao') {
            if (this.props.gerenciarAtendimento) {
                if (!this.props.isDN && (this.props.atendimento.solicitacaoStatus == null || this.props.atendimento.solicitacaoStatus.descricao === 'Não Conforme') && this.checkIfCanEditIndicadores()) {
                    return <GenericButton color={'darkBlue'} label={'Registrar anexos'} subClass={'basic-button'} click={() => { this.props.showLoading(); this.props.openAnexosScreen(true); }} />
                }
            } else if (this.props.isDN && this.props.atendimento.solicitacaoStatus && this.props.atendimento.solicitacaoStatus.descricao === 'Em Análise') {
                return <GenericButton color={'darkBlue'} label={'Avaliar anexos'} subClass={'basic-button'} click={() => { this.props.showLoading(); this.props.openAnexosScreen(true); }} />
            }
        }
    }

    render() { 
        return (
            <Grid item xs={12}>
                { this.props.isModuloNacional ?
                    <div className={'greyDiv'}> 
                        { this.buttonAnexos() }
                        <h2 className={'font-blue'}>{this.props.title}</h2>
                        <Grid container>
                        { this.anexos && this.anexos.length > 0 ?
                            this.anexos
                                .filter(anexo => anexo.nomeArquivo)
                                .map(
                                (anexo, index) =>  
                                <Grid container key={index}>
                                    {anexo.solicitacaoStatus ? 
                                        <Grid item xs={1} className={"tooltip-solicitacao"} style={{ marginTop: '5px'}}>
                                            <span className={'tooltip-info anexos'}> {anexo.solicitacaoStatus.chave === 'conforme' ? 'Aprovado' : 'Reprovado'} </span>
                                            <img alt={''} src={anexo.solicitacaoStatus.chave === 'conforme' ? '/assets/icons/aprovado.svg' : '/assets/icons/reprovado.svg'}  />
                                        </Grid>
                                    :
                                        <Grid item xs={1} className={"tooltip-solicitacao"}  style={{ marginTop: '5px'}}>
                                            <span className={'tooltip-info anexos'}> Não avaliado </span>
                                            <img alt={''} src={'/assets/icons/nao-avaliado.svg'} />
                                        </Grid>
                                    }
                                    
                                    <Grid item xs={4} style={{ marginTop: '4px'}}>{anexo.anexoReferencial.nome}</Grid>
                                    <Grid item xs={7}>
                                        <Chip key={index}
                                            label={<label style={{ display: 'flex', alignItems: 'inherit', marginTop: '20px' }}>         
                                                <MdAttachFile size={20} rotate={180} style={{ marginRight: '5px', cursor: 'pointer', transform: 'rotate(90deg)' }} onClick={() => { DownloadFilesNewBackend(anexo.urlAssinada, anexo.nomeArquivo) }} />
                                                <label onClick={() => { if (this.props.isDN) {OpenFile(anexo.urlAssinada, anexo.nomeArquivo);} }} className={this.props.isDN ? "grid-chip-file" : ''}>{ this.showFileName(anexo.nomeArquivo)}</label></label>}
                                        /> 
                                    </Grid>      
                                </Grid>                         
                                ) : <p>Este atendimento não possui anexos.</p>}
                        </Grid>
                    </div> :
                    <div className={'greyDiv'}> 
                        <h2 className={'font-blue'}>{this.props.title}</h2>
                        <Grid item xs={12}>
                            {this.props.list.length > 0 ?
                                this.props.list.map(
                                    (anexo, index) => 
                                    this.props.proposta ? 
                                    !anexo.url.includes("pesquisa_satisfacao") ?
                                        <Chip key={index} onClick={() => { DownloadFiles(anexo.url, anexo.nomeArquivo) }}
                                        style={{cursor: 'pointer', marginRight: '10px', marginBottom: '10px'}}
                                        label={<label style={{ display: 'flex', alignItems: 'center',  marginTop: '13px', cursor: 'pointer'}}>         
                                            <MdAttachFile size={20} rotate={180} style={{ marginRight: '5px', marginBottom: '2px', cursor: 'pointer', transform: 'rotate(90deg)' }} />
                                            <p>{ this.showFileName(anexo.nomeArquivo)}</p></label>}
                                        /> : null
                                    :                                   
                                        <Chip key={index} onClick={() => { DownloadFiles(anexo.url, anexo.nomeArquivo) }}
                                            style={{cursor: 'pointer', marginRight: '10px', marginBottom: '10px'}}
                                            label={<label style={{ display: 'flex', alignItems: 'center', marginTop: '13px', cursor: 'pointer' }}>         
                                                <MdAttachFile size={20} rotate={180} style={{ marginRight: '5px', marginBottom: '2px', cursor: 'pointer', transform: 'rotate(90deg)' }} />
                                                <p >{ this.showFileName(anexo.nomeArquivo)}</p></label>}
                                        /> 
                                    ) : <p>Este atendimento não possui anexos.</p>}
                         </Grid>
                    </div> }                   
            </Grid>
        )
    }
}